<template>
    <div class="widget-panel">
        <div class="title" v-if="title || $slots.title">
            <slot name="title">{{title}}</slot>
        </div>

        <div class="content">
            <slot></slot>
        </div>

        <div class="footer-left" v-if="$slots['footer-left']">
            <slot name="footer-left"></slot>
        </div>

        <div class="footer-right" v-if="$slots['footer-right']">
            <slot name="footer-right"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: `widget-panel`,
    props: {
        title: {type: String}
    }
}
</script>

<style lang="scss" scoped>
.widget-panel {
    --panel-margins: 50px;

    background: url('../assets/images/bg-eyein-network.png') center no-repeat;
    background-size: contain;
    padding: 30px 30px 50px;
    text-align: center;
    color: white;
    font-size: 20pt;
    max-width: calc(100% - var(--panel-margins) * 2);
    height: calc(100% - var(--panel-margins) * 2);
    margin: 50px auto;
    aspect-ratio: 16/9;

    .title {
        text-transform: uppercase;
        font-size: 30pt;
        margin-top: 0;
        margin-bottom: 15px;
    }

    .content {

    }

}
</style>
