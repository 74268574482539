import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* eslint-disable quotes */

export default new Router({
    mode: `history`,
    routes: [
        {
            path: `/`,
            name: `home`,
            alias: `/home`,
            component: () => import('./views/home.vue')
        },
        {
            path: `/widget/iframe/:id`,
            name: `widget-iframe`,
            component: () => import('./views/widget-iframe.vue')
        },
        {
            path: `/widget/map/:id`,
            name: `widget-map`,
            component: () => import('./views/widget-map.vue')
        },
        {
            path: `/widget/slideshow/:id`,
            name: `widget-slideshow`,
            component: () => import('./views/widget-slideshow.vue')
        },
        {
            path: `/widget/daily-menu/:id`,
            name: `widget-daily-menu`,
            component: () => import('./views/widget-daily-menu.vue')
        },
        {
            path: `/widget/number-ticket/:id`,
            name: `widget-number-ticket`,
            component: () => import('./views/widget-number-ticket.vue')
        }
    ]
});
