<template>
    <div class="app-page" :class="{ 'fullscreen': fullscreen}">
        <slot name="page-header"></slot>
        <component :is="noContainer ? 'div' : 'b-container'">
            <slot></slot>
        </component>
    </div>
</template>

<script>

export default {
    name: `app-page`,
    props: {
        fullscreen: {type: Boolean},
        noContainer: {type: Boolean}
    }
}
</script>

<style lang="scss" scoped>
.app-page {
    background: linear-gradient(180deg,#f1f5f9,#edf8f2);

    &.fullscreen {
        width: 100vw;
        height: 100vh;
    }
}
</style>
