<template>
    <div class="widget-container" :class="{ 'centered': centered }" :style="containerStyle">
        <template v-if="!loading && widget && !widget.loading">
            <template v-if="notFound || (widget && widget.error)">
                <e-empty-placeholder title="Widget Not Found or Misconfigured" :img-src="require('../assets/icons/not-found-sad.png')">
                    <template>{{$route.params.id}}</template>
                </e-empty-placeholder>
            </template>
            <template v-else>
                <slot></slot>
            </template>
        </template>
        <div class="loading" :class="{ 'hidden': !loading && widget && !widget.loading }"/>
    </div>
</template>

<script>

import EEmptyPlaceholder from "../../vue-components/components/e-empty-placeholder.vue";

export default {
    name: `widget-container`,
    components: {EEmptyPlaceholder},
    props: {
        name: {type: String},
        widget: {type: Object},
        centered: {type: Boolean},
        loading: {type: Boolean},
        backgroundImage: {type: String},
        backgroundColor: {type: String},
        notFound: {type: Boolean}
    },
    computed: {
        containerStyle() {
            let style = {};
            if (this.backgroundImage) {
                style.backgroundImage = `url(${this.backgroundImage})`;
            }
            if (this.backgroundColor) {
                style.backgroundColor = this.backgroundColor;
            }
            return style;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/global";

.widget-container {
    background: linear-gradient(180deg, #000, #111 80%, #142525 100%);
    color: #fff;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-size: cover;

    &.centered {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999999;
        background: linear-gradient(180deg, #000, #111 80%, #142525 100%);
        transition: opacity 1s;
        opacity: 1;

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }
}
</style>
