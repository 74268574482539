import Vue from 'vue';
import Vuex from 'vuex';
import Storage from '../vue-components/helpers/Storage.js'
import Network from "../vue-components/helpers/Network.js";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        env: localStorage.getItem(`env`) || `dev`
    },
    getters: {
        currentUser(state) {
            if (state.user) {
                return state.user;
            } else {
                return null;
            }
        },
        isAuthenticated(state) {
            return !!state.user;
        },
        env(state) {
            return state.env;
        }
    },
    mutations: {
        startLogin(state) {
            state.loginStatus = `loading`
        },
        authSuccess(state, user) {
            state.loginStatus = `success`;
            state.user = user
        },
        notConnected(state) {
            state.loginStatus = `not_connected`;
        },
        authError(state) {
            state.loginStatus = `error`
        },
        loggedOut(state) {
            state.loginStatus = `logged_out`;
            state.user = null
        }
    },
    actions: {
        getCurrentUser({commit}) {
            return new Promise((resolve, reject) => {
                commit(`startLogin`);
                Storage.get(`token`)
                    .then(token => {
                        if (token) {
                            Network.setToken(token);

                            Network.get(`/api/auth/current-user`)
                                .then(response => {
                                    if (response.data) {
                                        commit(`authSuccess`, response.data);
                                        resolve();
                                    }
                                })
                                .catch(err => {
                                    commit(`authError`, err);
                                    Storage.remove(`token`).then();
                                    Network.removeToken();
                                    reject(err);
                                });
                        } else {
                            commit(`notConnected`);
                            resolve();
                        }
                    })
                    .catch(() => {
                        resolve();
                    });
            });
        },
        login({commit}, options) {
            return new Promise((resolve, reject) => {
                commit(`startLogin`);
                Network.post(options.url, options.params)
                    .then(response => {
                        const user = response.data;
                        Network.setToken(user.token);
                        commit(`authSuccess`, user);
                        resolve(user);
                    })
                    .catch(err => {
                        commit(`authError`, err);
                        Storage.remove(`token`).then();
                        Network.removeToken();
                        reject(err);
                    });
            });
        },
        logout({commit}) {
            return new Promise(resolve => {
                commit(`loggedOut`);
                Storage.remove(`token`).then();
                Network.removeToken();
                resolve();
            });
        }
    },
    modules: {}
});
