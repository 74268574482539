import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store.js';
import "../vue-components/helpers/vue-mixin.js";

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import VueSwal from 'vue-swal'
import moment from 'moment';
import VueMoment from 'vue-moment'
import VHotkey from 'v-hotkey'
import appPage from '/src/layout/app-page.vue'
import pageHeader from '/src/layout/page-header.vue'
import widgetContainer from '/src/layout/widget-container.vue'
import widgetPanel from '/src/layout/widget-panel.vue'

import '../vue-components/helpers/vue-filters.js';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSwal);
Vue.use(VueMoment, {
    moment
});
Vue.use(VHotkey);

Vue.component(`app-page`, appPage);
Vue.component(`page-header`, pageHeader);
Vue.component(`widget-container`, widgetContainer);
Vue.component(`widget-panel`, widgetPanel);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount(`#app`);
